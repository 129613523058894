.hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
        url('https://www.personalfinances.eu/images/parallax/mainheader.jpg');
    background-position: center;
    background-size: cover;
    color: #fff;
    min-height: 100vh;
}

.heroHeading {
    padding: 15% 0;

    & > * {
        margin-top: 16px;
    }

    h1 {
        font-size: 42px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin-bottom: 0.3em;

        @media screen and (min-width: 960px) {
            font-size: 80px;
            letter-spacing: 0.5px;
        }
    }
}
.business::after {
    border-top: 3px solid #fff;
    content: '';
    display: block;
    margin-bottom: 0;
    margin-top: 8px;
    width: 26px;
}
