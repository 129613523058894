.why-us {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px 70px;

    @media screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr;
    }

    &__card {
        display: flex;

        h3 {
            margin: 0;
            color: #333;
        }

        p {
            margin-top: 8px;
            color: rgb(119, 119, 119);
        }
    }

    &__icon {
        color: #bbb;
        padding: 7px 30px 0 0;

        svg {
            width: 45px;
            height: 45px;
        }
    }
}

#whyus {
    background-color: #f7f9fb;
}
