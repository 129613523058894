@mixin column-row-switcher {
    align-items: center;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 960px) {
        flex-direction: row;
    }
}

@mixin header-white {
    background: white;
    color: black;
    box-shadow: 0 12px 12px #0001;
}

@mixin header-transparent {
    background: transparent;
    color: white;
    box-shadow: none;
}

@mixin logo-dark {
    &-dark {
        display: block;
    }
    &-light {
        display: none;
    }
}

@mixin logo-light {
    &-dark {
        display: none;
    }
    &-light {
        display: block;
    }
}

$padding: 20px;

.header {
    @include header-white;
    padding: $padding 0;
    position: fixed;
    text-transform: uppercase;
    transition: all 0.3s ease;
    width: 100%;
    z-index: 100;

    @media screen and (min-width: 960px) {
        @include header-transparent;
        color: #fff;

        &--scrolled {
            @include header-white;

            .header__logo {
                @include logo-dark;
            }
        }
    }

    &__logo {
        @include logo-dark;
        margin-right: auto;

        @media screen and (min-width: 960px) {
            @include logo-light;
        }
    }

    &__menu {
        $menu-item-padding: 2rem;

        @include column-row-switcher;
        font-size: 0.75rem;
        @media screen and (max-width: 959px) {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease;

            &--is-open {
                max-height: 6 * (2 * $menu-item-padding + 1.6);
            }
        }

        li {
            @media screen and (min-width: 960px) {
                & + li {
                    margin-left: 2.5em;
                }
            }

            a {
                color: inherit;
                display: block;
                font-size: inherit;
                font-weight: bold;
                padding: $menu-item-padding 0;
                transition: color 0.3s ease;
                white-space: nowrap;
                position: relative;
                &::after {
                    position: absolute;
                    bottom: 1.25rem;
                    left: 0;
                    width: 0%;
                    height: 4px;
                    content: '';
                    background: #2250fc;
                    transition: 0.3s ease;
                }
                &:hover::after,
                &:focus::after {
                    width: 100%;
                }
            }
        }
    }

    &__menu-trigger {
        appearance: none;
        background: none;
        border: none;
        color: inherit;
        cursor: pointer;
        position: absolute;
        right: $padding;
        top: $padding;
        transition: color 0.3s ease;

        @media screen and (min-width: 960px) {
            display: none;
        }

        &:hover,
        &:focus {
            color: #2250fc;
        }

        svg {
            height: 2rem;
            width: 2rem;
        }
    }

    &__nav {
        @include column-row-switcher;
    }
}
