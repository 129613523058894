.what-we-do__article {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    margin-top: 30px;
    line-height: 1.6;

    @media screen and (min-width: 960px) {
        align-items: center;
        display: grid;
        gap: 70px;
        grid-template-columns: 1fr 1fr;
    }
}

.features {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    li {
        margin-bottom: 10px;
        font-size: 14px;
        color: rgb(86, 86, 86);

        @media screen and (min-width: 960px) {
            font-size: 16px;
        }

        &::before {
            color: #2250fc;
            content: '>';

            font-weight: 900;
            margin-right: 10px;
        }
    }
}
