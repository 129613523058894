@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

/* Global rules */

:root {
    --font-body: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-title: 'Poppins', 'Helvetica', 'Arial', sans-serif;
}

*,
*::after,
*::before {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    color: #777;
    margin: 0;
    font-family: var(--font-body);
    line-height: 1.6;
}

a {
    text-decoration: none;
    color: rgb(0, 92, 191);
}

h1,
h2,
h3,
h4 {
    font-family: var(--font-title);
}

/* Utility classes */
.container {
    margin: 0 20px;
}
.list--none {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* @media screen and (min-width: 960px) {
    .container {
        margin: 0 auto;
        width: 1040px;
    }
} */

/* Components */

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.card {
    margin-right: 30px;
    text-align: left;
}

.card > * {
    margin-bottom: 16px;
    font-size: 14px;
}

.pf-h2 {
    color: black;
    position: relative;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 60px;
    margin-top: 0;
}

.pf-h2::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 100px;
    background-color: #2250fc;
    bottom: -30px;
    left: 0;
    right: 0;
}

.pf-subtitle {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 3em;
    max-width: 70ch;
}

.margin-top-50 {
    padding: 100px 0;
    margin-top: -50px;
}

.my-story p {
    color: #565656;
    font-size: 16px;
    line-height: 1.65714286em;
    margin-bottom: 12px;
}

.lead {
    display: block;
    font-size: 17.6429px;
    font-weight: 600;
    line-height: 30px;
    color: #565656;
    margin-bottom: 10px;
}

.icon {
    margin-right: 10px;
}

.contact img {
    display: block;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
}
.contact-left p {
    margin: 5px 0;
}

footer {
    display: grid;
    place-items: center;
    padding: 40px 0;
    color: rgb(86, 86, 86);
    background-color: rgb(248, 249, 250);
    margin-top: 70px;
}

.training {
    margin-top: 10px;
}

.problem-pic {
    width: 100%;
}

@media screen and (min-width: 960px) {
    .contact {
        display: flex;
    }

    .contact > * {
        flex: 1;
    }

    .contact-left {
        padding-right: 50px;
    }

    .cardContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .margin-auto {
        margin: auto !important;
    }
    .container {
        margin: 0 auto;
        width: 1040px;
    }
}

.business {
    display: block;
    font-family: var(--font-title);
    font-size: 12px !important;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 12px;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
}
