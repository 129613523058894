.arrow-btn {
    height: 50px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    opacity: 0.3;
    transition: 0.4s ease-in-out;
    &:hover,
    &:focus {
        opacity: 1;
    }
}
