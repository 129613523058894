.btn {
    background-color: #2250fc;
    border: 1px solid #2250fc;
    border-radius: 3em !important;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.5;
    outline: none;
    padding: 12px 34px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.4s ease;
    white-space: nowrap;

    &__label {
        display: inline-block;
        transform: translateX(-11px);
        transition: all 0.3s ease;
    }

    &__arrow {
        display: inline-block;
        margin-right: 10px;
        opacity: 0;
        transition: all 0.3s ease;
        transform: translateX(11px);
        svg {
            height: 12px;
            width: 12px;
        }
    }

    &:hover,
    &:focus {
        background-color: #0333e8;

        .btn__arrow {
            opacity: 1;
            transform: translateX(0);
        }

        .btn__label {
            transform: translateX(0);
        }
    }
}
